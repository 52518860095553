import { Disconnected, SECRET_KEY_ENCRYPT } from "@utils/config";
import axios from "axios";
import Cryptr from "cryptr";
import ls from "local-storage";

const cryptr = new Cryptr(SECRET_KEY_ENCRYPT);

export default function initAxios() {
  axios.defaults.headers.common = {
    Authorization: "Bearer " + ls.get("token"),
  };
  axios.defaults.headers.common["X-identifier-val"] = cryptr.decrypt(ls.get("currentcaisse"));

  axios.defaults.headers.common["X-identifier-account"] =
    typeof ls.get("current_account_caisse") !== "undefined" &&
    ls.get("current_account_caisse") !== null
      ? cryptr.decrypt(ls.get("current_account_caisse"))
      : Disconnected();
}
